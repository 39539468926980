// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

// temp fixes
@import "fixes";

// Spinkit
$spinkit-spinner-color: $body-color;
@import '~spinkit/scss/spinkit.scss';
.react-bs-table-container.table-borderless.dashboard-OrderTable.respon .react-bs-table-pagination .row {
    justify-content: center;
}
.react-bs-table-container.table-borderless.dashboard-OrderTable.respon .react-bs-table-pagination .row .col-md-6.col-xs-6.col-sm-6.col-lg-6:nth-child(odd) {
    margin-right: auto;
    margin-left: 0px;
}
.react-bs-table-container.table-borderless.dashboard-OrderTable.respon .react-bs-table-pagination .row .col-md-6.col-xs-6.col-sm-6.col-lg-6:nth-child(even) {
    max-width: 100%;
}
.react-bs-table-container.table-borderless.dashboard-OrderTable.respon .react-bs-table-pagination .row .col-md-6.col-xs-6.col-sm-6.col-lg-6 {
    max-width: max-content;
    margin: 0px auto;
}
.react-bs-table-container.table-borderless.dashboard-OrderTable.respon .react-bs-table-pagination .row .col-md-6.col-xs-6.col-sm-6.col-lg-6:nth-child(even) ul.react-bootstrap-table-page-btns-ul.pagination {
    float:left;
    justify-content: center;
}